
import { Link } from 'react-router-dom';
import RegionModel from '../../models/RegionModel';
import './RegionPreview.css';

interface RegionPreviewProps {
  region: RegionModel;
}

function RegionPreview(props: RegionPreviewProps) {

    const region: RegionModel = props.region;

    return (
        <Link to={{pathname:`/region/${region.id}`, state: region}}>
            <div className="regionComponent" key={region.id}>
                <p>{region.name}</p>
                {(region.picture != null) 
                ? <img className="regionImage" 
                       src={region.picture}
                       alt={region.name} />
                : <img className="regionImage" 
                       src={process.env.PUBLIC_URL + '/Region.png'}
                       alt={region.name} />        
                }         
            </div>
        </Link>
    );
}

export default RegionPreview;